import React, { useState, useEffect } from "react";
import { FaCheck, FaChevronDown, FaChevronUp } from "react-icons/fa";

const sections = [
  {
    id: "Pathway",
    title: "Pathway Discovery",
    features: ["University Identification", "Course Identification"],
  },
  {
    id: "Application Bootcamp",
    title: "Application Bootcamp",
    features: [
      "CV guidance",
      "Review SOP/Course Specific questions in application",
      "LOR Guidance",
      "Scholarship application tips",
      "Comprehensive Scholarship Guidance",
      "Review Writing Sample/Essay",
      "Review Research Proposal",
      "Interview tips",
      "Comprehensive Interview Guidance",
    ],
  },
  {
    id: "Global Liftoff",
    title: "Global Liftoff",
    features: [
      "Post-Arrival University Support",
      "Post-University Career Guidance",
      "General Visa Guidance",
      "Time Management",
      "Alumni Networking",
      "Finance Guidance",
    ],
  },
];
export const ComparisionTable = () => {
  const [expandedSections, setExpandedSections] = useState([
    "Pathway",
    "Application Bootcamp",
    "Global Liftoff",
  ]);
  const toggleSection = (sectionId) => {
    setExpandedSections((prev) =>
      prev.includes(sectionId)
        ? prev.filter((id) => id !== sectionId)
        : [...prev, sectionId]
    );
  };
  const isExpanded = (sectionId) => expandedSections.includes(sectionId);
  useEffect(() => {
    if (!expandedSections.includes("Application Bootcamp")) {
      setExpandedSections((prev) => [...prev, "Application Bootcamp"]);
    }
  }, []);
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8 sm:py-12 md:py-16">
      <div className="text-center mb-8 sm:mb-10 md:mb-12">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 sm:mb-3 md:mb-4 text-primary">
          Compare Plans <span className="text-orange-500">&</span> Decide
        </h2>
        <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold mb-2 sm:mb-3 md:mb-4 text-primary">
          What's Best For You <span className="text-yellow-400">🚀</span>
        </h3>
      </div>
      <div className="overflow-x-auto text-primary -mx-4 sm:-mx-6 md:-mx-8">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="border-b border-primary">
                <th className="text-left p-2 sm:p-3 md:p-4 text-sm sm:text-base md:text-lg min-w-[120px] sm:min-w-[150px] md:min-w-[200px] text-primary">
                  Compare
                </th>
                {["Basic", "Pro", "Premium"].map((plan) => (
                  <th
                    key={plan}
                    className="p-2 sm:p-3 md:p-4 min-w-[100px] sm:min-w-[120px] md:min-w-[150px]"
                  >
                    <div className="text-center">
                      <div className="font-bold text-primary text-sm sm:text-base md:text-lg">
                        {plan}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-primary">
                <td className="p-2 sm:p-3 md:p-4 text-sm sm:text-base md:text-lg">
                  Program Duration
                </td>
                <td className="p-2 sm:p-3 md:p-4 text-center text-sm sm:text-base md:text-lg">
                  Upto 1 Month
                </td>
                <td className="p-2 sm:p-3 md:p-4 text-center text-sm sm:text-base md:text-lg">
                  Upto 3 Months
                </td>
                <td className="p-2 sm:p-3 md:p-4 text-center text-sm sm:text-base md:text-lg">
                  Upto 10 Months
                </td>
              </tr>
              <tr className="border-b border-primary">
                <td className="p-2 sm:p-3 md:p-4 text-sm sm:text-base md:text-lg">
                  Number of Applications
                </td>
                <td className="p-2 sm:p-3 md:p-4 text-center text-sm sm:text-base md:text-lg">
                  1
                </td>
                <td className="p-2 sm:p-3 md:p-4 text-center text-sm sm:text-base md:text-lg">
                  Upto 2
                </td>
                <td className="p-2 sm:p-3 md:p-4 text-center text-sm sm:text-base md:text-lg">
                  Upto 5
                </td>
              </tr>
              {sections.map((section) => (
                <React.Fragment key={section.id}>
                  <tr
                    className="border-b border-primary cursor-pointer bg-stone-200 hover:bg-stone-300 "
                    onClick={() => toggleSection(section.id)}
                  >
                    <td className="p-2 sm:p-3 md:p-4 flex items-center gap-2">
                      <span className="p-1 sm:p-2">
                        {isExpanded(section.id) ? (
                          <FaChevronUp className="text-gray-400 text-lg sm:text-xl md:text-2xl" />
                        ) : (
                          <FaChevronDown className="text-gray-400 text-lg sm:text-xl md:text-2xl" />
                        )}
                      </span>
                      <span className="text-sm sm:text-base md:text-lg">
                        {section.title}
                      </span>
                    </td>
                    <td className="p-2 sm:p-3 md:p-4 text-center"></td>
                    <td className="p-2 sm:p-3 md:p-4 text-center"></td>
                    <td className="p-2 sm:p-3 md:p-4 text-center"></td>
                  </tr>
                  {isExpanded(section.id) &&
                    section.features.map((feature, index) => (
                      <tr
                        key={index}
                        className="border-b border-primary bg-gray-50"
                      >
                        <td className="p-2 sm:p-3 md:p-4 pl-8 sm:pl-10 md:pl-12 text-sm sm:text-base md:text-lg">
                          {feature}
                        </td>
                        <td className="p-2 sm:p-3 md:p-4 text-center">
                          {section.id !== "Global Liftoff" && index < 2 && (
                            <FaCheck className="inline text-primary text-sm sm:text-base md:text-lg" />
                          )}
                        </td>
                        <td className="p-2 sm:p-3 md:p-4 text-center">
                          {section.id === "Pathway" && index < 2 && (
                            <FaCheck className="inline text-primary text-sm sm:text-base md:text-lg" />
                          )}
                          {section.id === "Application Bootcamp" &&
                            (index < 4 || index === 5 || index === 7) && (
                              <FaCheck className="inline text-primary text-sm sm:text-base md:text-lg" />
                            )}
                          {section.id === "Global Liftoff" &&
                            (index < 4 || index === 5) && (
                              <FaCheck className="inline text-primary text-sm sm:text-base md:text-lg" />
                            )}
                        </td>
                        <td className="p-2 sm:p-3 md:p-4 text-center">
                          <FaCheck className="inline text-primary text-sm sm:text-base md:text-lg" />
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ComparisionTable;
