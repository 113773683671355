import React, { useState } from "react";
import PricingPage from "./PricingPage";

function PricedetailsMentee() {
  const [showDescriptions, setShowDescriptions] = useState({
    pathway: false,
    bootcamp: false,
    globalLiftoff: false,
    bridgeToUniversity: false,
  });

  const toggleDescription = (section) => {
    setShowDescriptions((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  return (
    <div className="pt-20">
      <PricingPage />
    </div>
  );
}

export default PricedetailsMentee;
