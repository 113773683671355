import React from "react";
import { MdOutlineEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
const Contactus = () => {
  return (
    <div className="fixed flex flex-col items-center justify-center bottom-0 right-0 m-4 z-50">
      {" "}
      {/* Changed z value to 50 for highest visibility */}
      <a href="https://wa.me/447440196490" target="_blank" rel="noreferrer">
        <FaWhatsapp className="w-10 h-10 p-2 text-white text-center rounded-full bg-green-500 hover:scale-110 transition duration-300 mb-2" />
      </a>
      <a href="mailto:support@uni-bridge.com">
        <MdOutlineEmail className="w-10 h-10 p-2 text-white text-center rounded-full bg-blue-600 hover:scale-110 transition duration-300" />
      </a>
      <p className="font-semibold text-black"> Message us </p>
    </div>
  );
};
export default Contactus;
