import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaYoutube, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { SiBluesky } from "react-icons/si";
import supabase from "../config/supabaseClient";
import swal from "sweetalert2";

function Footer() {
  const navigate = useNavigate();
  const [showFeedbackBox, setShowFeedbackBox] = useState(false);
  const [feedbackType, setFeedbackType] = useState("suggestion");
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleFeedbackTypeChange = (e) => {
    setFeedbackType(e.target.value);
  };

  const handleFeedbackMessageChange = (e) => {
    setFeedbackMessage(e.target.value);
  };

  const handleSubmit = async () => {
    if (feedbackType && feedbackMessage) {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) {
        console.error("Error fetching session:", sessionError);
        return;
      }

      if (!sessionData.session) {
        swal.fire("Please login to submit feedback");
        return;
      }
      const userId = sessionData.session.user.id;
      console.log(userId);

      const { error: feedbackError } = await supabase.from("feedback").insert([
        {
          user_id: userId,
          feedback_type: feedbackType,
          feedback: feedbackMessage,
        },
      ]);
      if (feedbackError) {
        console.error("Error submitting feedback:", feedbackError);
      } else {
        swal.fire({
          title: "Feedback submitted",
          text: `Type: ${feedbackType}\nMessage: ${feedbackMessage}`,
          icon: "success",
        });
      }

      // Reset feedback fields after submission
      setFeedbackType("suggestion");
      setFeedbackMessage("");
      setShowFeedbackBox(false); // Optionally close the feedback box after submission
    } else {
      swal.fire("Please provide feedback");
    }
  };

  return (
    <footer className=" w-full bg-primary text-white p-5">
      <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center">
        <div>
          <h2
            className="text-4xl font-bold cursor-pointer font-league-spartan"
            onClick={() => navigate("/")}
          >
            Unibridge
          </h2>
          <h5 className="text-lg mb-4 ">
            Bridging futures, one step at a time
          </h5>
          <div className="flex space-x-4 my-3">
            <a
              href="https://www.linkedin.com/company/uni-bridge.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn className="text-2xl hover:text-blue-400 transition-colors" />
            </a>
            <a
              href="https://www.youtube.com/@Uni-bridge"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <FaYoutube className="text-2xl hover:text-red-500 transition-colors" />
            </a>
            <a
              href="https://www.instagram.com/the_unibridge"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram className="text-2xl hover:text-purple-500 transition-colors" />
            </a>
            <a
              href="https://bsky.app/profile/uni-bridge.bsky.social"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Bluesky"
            >
              <SiBluesky className="text-2xl hover:text-blue-500 transition-colors" />
            </a>
          </div>
        </div>
        <div className="text-sm md:text-base grid grid-cols-3 gap-4 md:gap-10">
          <div>
            <h3 className="font-semibold">Students</h3>
            <ul>
              <li>
                <Link to="/resources/undergrad">Undergraduate</Link>
              </li>
              <li>
                <Link to="/resources/postgrad">Postgraduate</Link>
              </li>
              <li>
                <Link to="/what-we-offer">What we offer?</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold">Work with us</h3>
            <ul>
              <li>
                <Link to="/beamentor">Mentors</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold">Company</h3>
            <ul>
              <li>
                <Link to="/who-we-are">Who we are?</Link>
              </li>
              <li>
                <Link to="/why-choose-us">Why choose us?</Link>
              </li>
              <li>
                <a href="mailto:support@uni-bridge.com">Contact us</a>
              </li>
              <li>
                <button
                  onClick={() => setShowFeedbackBox(true)}
                  className="bg-green-500 px-2 rounded"
                >
                  Feedback
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center text-sm mt-5">
        <Link to="/terms-and-conditions">
          Terms of Service|Privacy & Cookie Notice
        </Link>
        {/* <a href="#">Terms of Service</a> |
        <a href="#">Privacy & Cookie Notice</a> */}
      </div>

      {showFeedbackBox && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="relative bg-white text-primary rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <button
              onClick={() => setShowFeedbackBox(false)}
              className="mb-4 flex justify-end font-semibold text-purple-500"
            >
              Close
            </button>
            {/* <div className="flex items-center"> */}
            <label className="mb-2 font-medium text-secondary-200">
              Feedback type
            </label>
            <select
              className="w-full mb-2 p-2 border rounded"
              onChange={handleFeedbackTypeChange}
              placeholder="Select Feedback Type"
            >
              <option value="suggestion">Suggestion</option>
              <option value="issue">Issue</option>
              <option value="compliment">Compliment</option>
            </select>

            <textarea
              placeholder="Your feedback here..."
              className="w-full h-32 p-2 border rounded"
              onChange={handleFeedbackMessageChange}
            />
            <button
              onClick={handleSubmit}
              className="bg-primary text-white px-4 py-2 my-4 rounded-md"
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <p className="text-center text-xs mt-3">© 2024 Unibridge</p>
    </footer>
  );
}

export default Footer;
