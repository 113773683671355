import React, { useState } from "react";
import PricingPageMentor from "./PricingPageMentor";

function PricedetailsMentor() {
  const [showDescriptions, setShowDescriptions] = useState({
    pathway: false,
    bootcamp: false,
    globalLiftoff: false,
    bridgeToUniversity: false,
  });

  const toggleDescription = (section) => {
    setShowDescriptions((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="pt-20">
      <PricingPageMentor />
    </div>
  );
}

export default PricedetailsMentor;
