import React from "react";
import { FaLinkedin } from "react-icons/fa";
import Arjun_pic from "../../assets/images/ForAbout/Arjun_pic_crop.png";
import Guru_pic from "../../assets/images/ForAbout/Guru_pic.jpg";
import Sumeet_pic from "../../assets/images/ForAbout/Sumeet_crop.jpg";

function Whoweare() {
  return (
    <div className="pt-16 text-secondary-100">
      <div className="">
        <div className="md:min-h-96 bg-stone-100 flex flex-col items-center justify-center relative text-secondary-100">
          <h1 className="text-3xl md:text-5xl font-bold text-center py-10">
            Who We Are
          </h1>
          <div className=" text-secondary-200 font-medium text-sm md:text-lg mb-8 px-10 md:px-32 md:py-10">
            <p className="mb-4 md:mb-8">
              We have been where you are. Like you, we dreamed big and achieved
              but faced challenges when navigating the complex journey of
              applying to international universities to pursue higher education.
            </p>
            <p className="mb-4 md:mb-8 ">
              Now, we’re here to make your path smoother. Our platform is built
              on a passion for mentoring students like you—helping you find the
              right courses, the best universities, and guiding you through
              every step of the application process.
            </p>
            <p className="mb-4 md:mb-8">
              We connect you with mentors who’ve faced the same hurdles and know
              exactly what it takes to stand out.
            </p>
            <p>
              Your goals are our goals, and we are here to ensure that every
              student like you is set up for success as they navigate the path
              to their dreams.
            </p>
          </div>
        </div>

        <h2 className="text-3xl md:text-5xl font-bold my-8 text-center">
          Our Team
        </h2>
        <div className=" flex  flex-col md:flex-row gap-10 justify-center  mt-10 mb-20">
          <div className=" flex flex-col items-center justify-start max-w-sm mx-12">
            <img
              src={Arjun_pic}
              alt="Arjun"
              className="w-64 h-64 mt-0 rounded-full shadow-lg border-2 border-secondary-500 "
            />
            <h3 className="text-xl font-medium text-center mt-4 mb-1 flex items-center">
              CEO & Co-founder
              <a
                href="https://www.linkedin.com/in/arjun-vijeta-71426963/"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2"
              >
                <FaLinkedin className="text-[#0A66C2] text-xl cursor-pointer" />
              </a>
            </h3>
            <h3 className="text-xl font-bold text-center ">
              Arjun Vijeta, PhD
            </h3>

            <p className="text-gray-700 text-center">
              University of Cambridge, UK <br />
              IISER Pune, India
            </p>
          </div>
          <div className="flex flex-col items-center justify-start max-w-sm mx-12">
            <img
              src={Sumeet_pic}
              alt="Sumeet"
              className="w-64 h-64 rounded-full shadow-lg border-2 border-secondary-500 "
            />
            <h3 className="text-xl font-medium text-center mt-4 mb-1 flex items-center">
              Co-founder
              <a
                href="https://www.linkedin.com/in/sumeetgarg06"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2"
              >
                <FaLinkedin className="text-[#0A66C2] text-xl cursor-pointer" />
              </a>
            </h3>
            <h3 className="text-xl font-bold text-center ">Sumeet Garg</h3>
            <p className="text-gray-700 text-center">
              IIT Bombay, India <br />
              Ex-Ola, Ex-Grofers
            </p>
          </div>
          <div className="flex flex-col items-center justify-start max-w-sm mx-12">
            <img
              src={Guru_pic}
              alt="Guru"
              className="w-64 h-64 rounded-full shadow-lg border-2 border-secondary-500 "
            />
            <h3 className="text-xl font-medium text-center mt-4 mb-1 flex items-center">
              Co-founder
              <a
                href="https://www.linkedin.com/in/singh-gurashish"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2"
              >
                <FaLinkedin className="text-[#0A66C2] text-xl cursor-pointer" />
              </a>
            </h3>
            <h3 className="text-xl font-bold text-center ">
              Gurashish Singh, PhD
            </h3>
            <p className="text-gray-700 text-center">
              University of Cambridge, UK <br />
              Durham University, UK <br />
              University of Sydney, Australia
            </p>
          </div>
        </div>

        <div className="min-h-48 text-center mt-20 px-10 md:px-20 py-8 bg-stone-100 rounded-lg shadow">
          <p className="text-xl font-semibold text-secondary-200 italic">
            “Education is the most powerful tool we have to change our lives. No
            matter where you come from, if you work hard, seek opportunities,
            and never give up, the world will open its doors to you.”
          </p>
        </div>
      </div>
    </div>
  );
}

export default Whoweare;
