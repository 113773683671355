import React, { useState, useEffect } from "react";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa";
import supabase from "../config/supabaseClient";
import Arjun_pic from "../assets/images/ForAbout/Arjun_pic_crop.png";
import Guru_pic from "../assets/images/ForAbout/Guru_pic.jpg";
import Sumeet_pic from "../assets/images/ForAbout/Sumeet_crop.jpg";

const founders = [
  {
    name: "Arjun Vijeta, PhD",
    role: "CEO & Co-Founder",
    image: Arjun_pic,
    education: "University of Cambridge, UK\nIISER Pune, India",
    linkedin: "https://www.linkedin.com/in/arjun-vijeta-71426963/",
  },
  {
    name: "Sumeet Garg",
    image: Sumeet_pic,
    role: "Co-Founder",
    education: "IIT Bombay, India\nEx-Ola, Ex-Grofers",
    linkedin: "https://www.linkedin.com/in/sumeetgarg06",
  },
  {
    name: "Gurashish Singh, PhD",
    image: Guru_pic,
    role: "Co-Founder",
    education:
      "University of Cambridge, UK\nDurham University, UK\nUniversity of Sydney, Australia",
    linkedin: "https://www.linkedin.com/in/singh-gurashish",
  },
];

export default function ConnectWithFounders() {
  const [isOpen, setIsOpen] = useState(false);
  const [session, setSession] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const toggleDialog = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error fetching session:", error);
      } else {
        setSession(data.session);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    if (!session) {
      const hasDialogBeenShown = localStorage.getItem("founderDialogShown");

      if (!hasDialogBeenShown) {
        const handleScroll = () => {
          if (!hasScrolled) {
            setHasScrolled(true);
            setTimeout(() => {
              setIsOpen(true);
            }, 2000);
          }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    }
  }, [session, hasScrolled]);

  return (
    <div>
      <button
        onClick={toggleDialog}
        className="bg-transparent text-primary border-2 border-primary m-0 py-3 px-6 font-medium rounded transition duration-300 transform hover:scale-105 ml-4"
      >
        <div className="absolute -top-3 right-4 bg-green-600 text-white text-xs py-1 px-3 rounded-full">
          Free
        </div>
        Talk to founders
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="relative bg-white text-primary rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <button
              onClick={toggleDialog}
              className="cursor-pointer absolute top-0 font-bold right-0 text-red-800 px-2"
            >
              X
            </button>
            <h2 className="text-lg text-center mb-4">
              An initiative by the <b>University of Cambridge</b> and{" "}
              <b>IIT Bombay</b> alumni
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {founders.map((founder, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img
                    src={founder.image}
                    alt={founder.name}
                    className="w-32 h-32 rounded-full object-cover mb-2"
                  />
                  <h4 className="text-sm font-semibold flex">
                    {founder.name}
                    <a
                      href={founder.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-2"
                    >
                      <FaLinkedin className="text-[#0A66C2] text-sm mb-[-1]" />
                    </a>
                  </h4>
                  <p className="text-sm text-gray-600 flex items-center mb-1">
                    {founder?.role}
                  </p>
                </div>
              ))}
            </div>

            <div className="flex justify-center gap-4 mt-6">
              <a
                href="https://wa.me/447440196490"
                target="_blank"
                rel="noreferrer"
              >
                <button className="bg-green-500 text-white py-2 px-4 rounded flex items-center gap-2">
                  <FaWhatsapp />
                  Connect
                </button>
              </a>
              <a
                href="https://calendly.com/ourunibridge/20min?month=2024-11"
                target="_blank"
                rel="noreferrer"
              >
                <button className="bg-primary text-white py-2 px-4 rounded">
                  Book a free session
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
