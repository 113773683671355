import React, { useState, useEffect } from "react";
import { MdOutlineAddCircleOutline, MdEdit, MdDelete } from "react-icons/md";
import supabase from "../../config/supabaseClient";
import { format, parseISO } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";

function Sessiondetails({ userId, userType, selectedConnection, userName }) {
  // Session tracking states
  const [sessionTitle, setSessionTitle] = useState("");
  const [sessionFeedback, setSessionFeedback] = useState("");
  const [showAddSessionForm, setShowAddSessionForm] = useState(false);
  const [sessionRecords, setSessionRecords] = useState({});
  const [confirmingSessionId, setConfirmingSessionId] = useState(null);
  const [menteeFeedback, setMenteeFeedback] = useState("");

  // Updated phase info state
  const [showPhaseinfo, setShowphaseinfo] = useState([
    { title: "Phase 1: Pathway Discovery", visible: false },
    { title: "Phase 2: Application Bootcamp", visible: false },
    { title: "Phase 3: Global Liftoff", visible: false },
    { title: "Free Session", visible: false },
    { title: "Accelerator Session", visible: false },
    { info: false },
  ]);

  const [phase, setPhase] = useState("");

  // Session booking states
  const [sessions, setSessions] = useState([]);
  const [subject, setSubject] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editingSession, setEditingSession] = useState(null);
  const [formError, setFormError] = useState(""); // Added form error state

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const sessionsPerPage = 4;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);

  useEffect(() => {
    if (supabase && selectedConnection) {
      fetchSessions();
      fetchSessionRecords();
    }
  }, [selectedConnection]);

  const fetchSessions = async () => {
    if (!supabase || !selectedConnection) return;
    const { data, error } = await supabase
      .from("session_booking")
      .select("*")
      .eq("mentor", selectedConnection.mentor)
      .eq("mentee", selectedConnection.mentee)
      .gte("date", new Date().toISOString().split("T")[0]) // Only fetch future sessions
      .order("date", { ascending: true })
      .order("start_time", { ascending: true });
    if (!error) setSessions(data);
  };

  const fetchSessionRecords = async () => {
    if (!supabase || !selectedConnection) return;
    const { data, error } = await supabase
      .from("session_track")
      .select("*")
      .eq("mentor", selectedConnection.mentor)
      .eq("mentee", selectedConnection.mentee)
      .order("session_number", { ascending: true });

    if (!error) {
      const groupedSessions = data.reduce((acc, session) => {
        if (!acc[session.session_title]) {
          acc[session.session_title] = [];
        }
        acc[session.session_title].push(session);
        return acc;
      }, {});
      setSessionRecords(groupedSessions);
    }
  };

  const handleAddSessionRecord = async (e) => {
    e.preventDefault();
    if (!selectedConnection) return;

    const nextSessionNumber = (sessionRecords[sessionTitle]?.length || 0) + 1;

    const newSession = {
      mentor: selectedConnection.mentor,
      mentee: selectedConnection.mentee,
      session_number: nextSessionNumber,
      session_title: sessionTitle,
      feedback_mentor: sessionFeedback,
    };

    const { data, error } = await supabase
      .from("session_track")
      .insert([newSession]);

    if (error) {
      console.error("Error adding session:", error);
    } else {
      // console.log("Session Added:", data);
      setSessionRecords((prev) => ({
        ...prev,
        [sessionTitle]: [...(prev[sessionTitle] || []), newSession],
      }));
      setSessionTitle("");
      setSessionFeedback("");
      setShowAddSessionForm(false);
    }
  };

  // Updated handlePhaseClick function
  const handlePhaseClick = (title) => {
    setShowphaseinfo((prev) =>
      prev.map((phase) =>
        phase.title === title ? { ...phase, visible: !phase.visible } : phase
      )
    );
  };

  const handleConfirmSession = async (title, sessionNumber) => {
    if (!selectedConnection) return;

    const { data, error } = await supabase
      .from("session_track")
      .update({
        mentee_confirm: true,
        feedback_mentee: menteeFeedback,
      })
      .eq("mentor", selectedConnection.mentor)
      .eq("mentee", selectedConnection.mentee)
      .eq("session_title", title)
      .eq("session_number", sessionNumber);

    if (error) {
      console.error("Error confirming session:", error);
    } else {
      // console.log("Session Confirmed:", data);
      setSessionRecords((prev) => ({
        ...prev,
        [title]: prev[title].map((session) =>
          session.session_number === sessionNumber
            ? {
                ...session,
                mentee_confirm: true,
                feedback_mentee: menteeFeedback,
              }
            : session
        ),
      }));
      setConfirmingSessionId(null);
      setMenteeFeedback("");
    }
  };

  const createOrUpdateSession = async () => {
    if (!supabase || !selectedConnection) return;

    if (!subject || !date || !startTime || !endTime || !meetingLink) {
      setFormError(
        "All fields are mandatory. Please fill in all the required fields."
      );
      return;
    }

    const sessionData = {
      subject,
      date,
      start_time: startTime,
      end_time: endTime,
      meeting_link: meetingLink,
      mentor: selectedConnection.mentor,
      mentee: selectedConnection.mentee,
    };

    let error;

    if (editingSession) {
      const { error: updateError } = await supabase
        .from("session_booking")
        .update(sessionData)
        .eq("id", editingSession.id);
      error = updateError;
    } else {
      const { error: insertError } = await supabase
        .from("session_booking")
        .insert([{ ...sessionData, created_by: userId }]);
      error = insertError;
    }

    if (error) {
      console.error("Error creating/updating session:", error);
      setFormError(
        "An error occurred while saving the session. Please try again."
      );
    } else {
      fetchSessions();
      setShowCreateDialog(false);
      setSubject("");
      setDate("");
      setStartTime("");
      setEndTime("");
      setMeetingLink("");
      setEditingSession(null);
      setFormError("");
    }
  };

  const editSession = (session) => {
    setEditingSession(session);
    setSubject(session.subject);
    setDate(session.date);
    setStartTime(session.start_time);
    setEndTime(session.end_time);
    setMeetingLink(session.meeting_link);
    setShowCreateDialog(true);
  };

  const deleteSession = async (sessionId) => {
    if (!supabase) return;
    const { error } = await supabase
      .from("session_booking")
      .delete()
      .eq("id", sessionId);

    if (error) {
      console.error("Error deleting session:", error);
    } else {
      fetchSessions();
      setShowDeleteConfirmation(false);
      setSessionToDelete(null);
    }
  };

  const handleDeleteConfirmation = (session) => {
    setSessionToDelete(session);
    setShowDeleteConfirmation(true);
  };

  // [UPDATED] New function to delete session record
  const deleteSessionRecord = async (title, sessionNumber) => {
    if (!supabase || !selectedConnection) return;
    const { error } = await supabase
      .from("session_track")
      .delete()
      .eq("mentor", selectedConnection.mentor)
      .eq("mentee", selectedConnection.mentee)
      .eq("session_title", title)
      .eq("session_number", sessionNumber);

    if (error) {
      console.error("Error deleting session record:", error);
    } else {
      fetchSessionRecords();
      setShowDeleteConfirmation(false);
      setSessionToDelete(null);
    }
  };

  const addToCalendar = (session) => {
    try {
      if (!session.date || !session.start_time || !session.end_time) {
        throw new Error(
          "Invalid session data: missing date, start time, or end time"
        );
      }

      // Construct the full datetime using the date and times
      const startDate = parseISO(
        `${session.date.slice(0, 10)}T${session.start_time}`
      );
      const endDate = parseISO(
        `${session.date.slice(0, 10)}T${session.end_time}`
      );

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        throw new Error("Invalid date or time");
      }

      const startTime = format(startDate, "yyyyMMdd'T'HHmmss'Z'");
      const endTime = format(endDate, "yyyyMMdd'T'HHmmss'Z'");

      const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${startTime}
DTEND:${endTime}
SUMMARY:${session.subject}
DESCRIPTION:Join the session using this link: ${session.meeting_link}
END:VEVENT
END:VCALENDAR`;

      const blob = new Blob([icsContent.trim()], {
        type: "text/calendar;charset=utf-8",
      });
      saveAs(blob, `${session.subject}.ics`);
    } catch (error) {
      console.error("Error creating calendar event:", error);
      alert(`Failed to create calendar event: ${error.message}`);
    }
  };

  const addToGoogleCalendar = (session) => {
    try {
      if (!session.date || !session.start_time || !session.end_time) {
        throw new Error(
          "Invalid session data: missing date, start time, or end time"
        );
      }

      const startDate = parseISO(
        `${session.date.slice(0, 10)}T${session.start_time}`
      );
      const endDate = parseISO(
        `${session.date.slice(0, 10)}T${session.end_time}`
      );

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        throw new Error("Invalid date or time");
      }

      const startTime = startDate.toISOString().replace(/-|:|\.\d\d\d/g, "");
      const endTime = endDate.toISOString().replace(/-|:|\.\d\d\d/g, "");

      const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        session.subject
      )}&dates=${startTime}/${endTime}&details=${encodeURIComponent(
        `Join the session using this link: ${session.meeting_link}`
      )}&sf=true&output=xml`;

      window.open(url, "_blank");
    } catch (error) {
      console.error("Error adding to Google Calendar:", error);
      alert(`Failed to add to Google Calendar: ${error.message}`);
    }
  };

  // Pagination logic
  const indexOfLastSession = currentPage * sessionsPerPage;
  const indexOfFirstSession = indexOfLastSession - sessionsPerPage;
  const currentSessions = sessions.slice(
    indexOfFirstSession,
    indexOfLastSession
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex flex-col lg:flex-row w-full gap-4 mt-4 mb-6 lg:mr-6">
      {/* Session Records Section */}
      <div className="flex flex-col lg:w-8/12 bg-secondary-500 bg-opacity-30 p-4 lg:px-8 rounded-lg">
        <h2 className="font-semibold text-center my-4 text-xl">
          Session log with {userName}
        </h2>
        <h3 className="font-semibold">
          You have {Object.values(sessionRecords).flat().length} session(s) with
          the selected connection
        </h3>
        {Object.entries(sessionRecords).map(([title, sessions]) => (
          <div key={title} className="mt-4">
            <h4 className="font-semibold text-base flex item-center">
              {title}
              <button onClick={() => handlePhaseClick(title)}>
                <IoIosInformationCircleOutline className="mt-1 ml-1" />
              </button>
            </h4>
            {sessions.map((session) => (
              <div key={`${title}-${session.session_number}`} className="mt-2">
                <div className="bg-stone-50 p-2 rounded-lg flex items-center justify-between">
                  <div className="flex-grow">
                    <span className="mr-2">
                      {title} - Session {session.session_number}:
                    </span>
                    {session.mentee_confirm ? (
                      <span className="text-green-700">
                        Mentee confirmed the session
                      </span>
                    ) : (
                      <span className="text-red-500">
                        Waiting for mentee confirmation
                      </span>
                    )}
                  </div>
                  <button
                    onClick={() => handleDeleteConfirmation({ title, session })}
                    className="flex-shrink-0 ml-2"
                  >
                    <MdDelete className="h-5 w-5 text-red-800" />
                  </button>
                </div>

                {session.session_number === 1 &&
                  userType === "Mentee" &&
                  title === "Free Session" && (
                    <p className="bg-purple-300 p-2 rounded-lg mt-2 text-sm">
                      Please make the payment to continue the mentorship. Choose
                      your plan.
                      <Link to={"/pricedetails_mentee"}>
                        <button className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark transition duration-300 ml-1">
                          Choose Plan
                        </button>
                      </Link>
                    </p>
                  )}
                {session.session_number === 1 &&
                  userType === "Mentor" &&
                  title === "Free Session" && (
                    <p className="bg-purple-300 p-2 rounded-lg mt-2 text-sm">
                      Reminder: Please wait for mentee payment confirmation
                      email before scheduling any further session.
                    </p>
                  )}
                {userType === "Mentee" && !session.mentee_confirm && (
                  <div className="mt-1">
                    {confirmingSessionId ===
                    `${title}-${session.session_number}` ? (
                      <div className="flex flex-col">
                        <textarea
                          placeholder="Provide your feedback"
                          className="border border-gray-300 p-2 rounded mb-2"
                          value={menteeFeedback}
                          onChange={(e) => setMenteeFeedback(e.target.value)}
                          required
                        />
                        <button
                          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                          onClick={() =>
                            handleConfirmSession(title, session.session_number)
                          }
                        >
                          Confirm & Submit Feedback
                        </button>
                        <button
                          className="mt-2 text-red-500"
                          onClick={() => setConfirmingSessionId(null)}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button
                        className="mt-1 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                        onClick={() =>
                          setConfirmingSessionId(
                            `${title}-${session.session_number}`
                          )
                        }
                      >
                        Confirm & Provide Feedback
                      </button>
                    )}
                  </div>
                )}
                {userType === "Mentee" && session.mentee_confirm && (
                  <p className="text-green-600 mt-1">Session confirmed.</p>
                )}
              </div>
            ))}
          </div>
        ))}

        {userType === "Mentor" && (
          <div className="flex flex-col mt-4">
            <p className="mb-2 text-sm italic text-red-500">
              Please remember to add a written record and feedback of your
              session once it is completed, and kindly ask the mentee to confirm
              it on their end. This ensures smooth tracking and verification of
              each session.
            </p>
            <button
              className="px-4 py-2 rounded hover:bg-primary-dark transition duration-300 flex flex-row items-center"
              onClick={() => setShowAddSessionForm(!showAddSessionForm)}
            >
              Add Session <MdOutlineAddCircleOutline className="w-6 h-6" />
            </button>
          </div>
        )}

        <form
          className={`mt-4 ${
            showAddSessionForm ? "block" : "hidden"
          } session-details-form`}
          onSubmit={handleAddSessionRecord}
        >
          <div className="flex flex-col">
            <select
              className="border border-gray-300 p-2 rounded mb-2"
              value={sessionTitle}
              onChange={(e) => setSessionTitle(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Session Title
              </option>
              <option value="Free Session">Free Session</option>
              <option value="Phase 1: Pathway Discovery">
                Phase 1: Pathway Discovery
              </option>
              <option value="Phase 2: Application Bootcamp">
                Phase 2: Application Bootcamp
              </option>
              <option value="Phase 3: Global Liftoff">
                Phase 3: Global Liftoff
              </option>
              <option value="Accelerator Session">Accelerator Session</option>
            </select>
            <textarea
              placeholder="Feedback"
              className="border border-gray-300 p-2 rounded mb-2"
              value={sessionFeedback}
              onChange={(e) => setSessionFeedback(e.target.value)}
              required
            />
            <div className="flex gap-4">
              <button
                type="submit"
                className="bg-primary text-white px-4 py-2 rounded hover:scale-105 transition duration-300"
              >
                Submit
              </button>
              <button
                type="button"
                className="bg-red-300 text-black px-4 py-2 rounded hover:scale-105 transition duration-300"
                onClick={() => setShowAddSessionForm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>

      {showPhaseinfo.some((phase) => phase.visible) && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            {showPhaseinfo.map(
              (phase) =>
                phase.visible && (
                  <div key={phase.title}>
                    <h2 className="font-bold text-secondary-200 text-lg flex justify-between mb-2">
                      {phase.title}
                      <button onClick={() => handlePhaseClick(phase.title)}>
                        {" "}
                        x
                      </button>
                    </h2>

                    <h3 className="font-semibold text-secondary-100 text-base flex justify-between mb-2">
                      Expected Outcomes
                    </h3>

                    <ul className="bg-slate-100 px-2">
                      {phase.title === "Phase 1: Pathway Discovery" && (
                        <>
                          <p className="text-sm mb-4">
                            This phase includes up to 2 free sessions, which can
                            be merged into one if expected outcomes are met.
                            However, <b>be sure to record both sessions</b>,
                            even if only one is taken.
                          </p>
                          <li>📋 Clarity on Aspirations</li>
                          <li>
                            📋 Clear understanding on target universities and
                            courses
                          </li>
                          <li>📋 Strategic Application Roadmap</li>
                        </>
                      )}
                      {phase.title === "Phase 2: Application Bootcamp" && (
                        <>
                          <li>📋 Strong application proposals</li>
                          <li>📋 Standout SOPs</li>
                          <li>
                            📋 Curated and compelling Scholarship applications
                            (if applicable)
                          </li>
                          <li>📋 Successfully Submitted application</li>
                        </>
                      )}
                      {phase.title === "Phase 3: Global Liftoff" && (
                        <>
                          <li>📋 Visa processes</li>
                          <li>📋 Financial planning</li>
                          <li>📋 Accommodation suggestions</li>
                          <li>📋 Pre-departure checklist</li>
                        </>
                      )}
                      {phase.title === "Accelerator Session" && (
                        <>
                          <li>🚀 Decided between mentor and mentee</li>
                        </>
                      )}
                    </ul>
                  </div>
                )
            )}
          </div>
        </div>
      )}

      {/* Session Booking Section */}
      <div className="lg:w-4/12 bg-secondary-500 bg-opacity-30 p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-6">
          {userType === "Mentor" && (
            <button
              onClick={() => setShowCreateDialog(true)}
              className="bg-primary text-white px-4 py-2 rounded-md hover:scale-105 transition duration-300"
            >
              Create Session
            </button>
          )}
        </div>

        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Upcoming Sessions</h2>
          {currentSessions.map((session) => (
            <div key={session.id} className="bg-gray-50 p-4 rounded-md">
              <div className="flex">
                <h3 className="font-medium">{session.subject}</h3>
                <button onClick={() => editSession(session)}>
                  <MdEdit className="h-5 w-5 ml-2" />
                </button>
                <button onClick={() => handleDeleteConfirmation(session)}>
                  <MdDelete className="h-5 w-5 ml-2 text-red-800" />
                </button>
              </div>

              <p className="text-sm text-gray-600">
                {format(parseISO(session.date), "PPP")}, {session.start_time} -{" "}
                {session.end_time} GMT
              </p>

              <div className="flex items-center justify-center mt-2 text-sm">
                <div className="">
                  <a
                    href={session.meeting_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex bg-purple-600 text-white px-4 py-2 mr-2 rounded-md hover:scale-105 transition duration-300 items-center gap-2"
                  >
                    <FaExternalLinkAlt />
                    Join
                  </a>
                </div>
                <div>
                  Add to
                  <button
                    onClick={() => addToGoogleCalendar(session)}
                    className="text-blue-600 mx-1"
                  >
                    Google
                  </button>
                  .
                  <button
                    onClick={() => addToCalendar(session)}
                    className="text-blue-600 mx-1"
                  >
                    Calendar
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {Array.from(
            { length: Math.ceil(sessions.length / sessionsPerPage) },
            (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === i + 1
                    ? "bg-primary text-white"
                    : "bg-gray-200"
                }`}
              >
                {i + 1}
              </button>
            )
          )}
        </div>
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this session?</p>
            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (sessionToDelete.title) {
                    deleteSessionRecord(
                      sessionToDelete.title,
                      sessionToDelete.session.session_number
                    );
                  } else {
                    deleteSession(sessionToDelete.id);
                  }
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Create Session Dialog */}
      {showCreateDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Create a Session</h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="subject"
                  className="block text-base font-medium text-gray-700"
                >
                  Session Subject <span className="text-red-500">*</span>
                </label>

                <select
                  className="border border-gray-300 p-2 rounded mb-2"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Session Title
                  </option>
                  <option value="Free Session">Free Session</option>
                  <option value="Phase 1: Pathway Discovery">
                    Phase 1: Pathway Discovery
                  </option>
                  <option value="Phase 2: Application Bootcamp">
                    Phase 2: Application Bootcamp
                  </option>
                  <option value="Phase 3: Global Liftoff">
                    Phase 3: Global Liftoff
                  </option>
                  <option value="Accelerator Session">
                    Accelerator Session
                  </option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="date"
                  className="block text-base font-medium text-gray-700"
                >
                  Date <span className="text-red-500">*</span>
                </label>
                <input
                  id="date"
                  type="date"
                  value={date ? format(new Date(date), "yyyy-MM-dd") : ""}
                  onChange={(e) => setDate(e.target.value)}
                  className="mt-1 block w-full text-lg rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label
                  htmlFor="time"
                  className="block text-base font-medium text-gray-700"
                >
                  Start Time (GMT) <span className="text-red-500">*</span>
                </label>
                <input
                  id="time"
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className="mt-1 block w-full text-lg rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label
                  htmlFor="time"
                  className="block text-base font-medium text-gray-700"
                >
                  End Time (GMT) <span className="text-red-500">*</span>
                </label>
                <input
                  id="time"
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  className="mt-1 block w-full text-lg rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label
                  htmlFor="link"
                  className="block text-base font-medium text-gray-700"
                >
                  Meeting Link <span className="text-red-500">*</span>
                </label>
                <input
                  id="link"
                  type="text"
                  value={meetingLink}
                  onChange={(e) => setMeetingLink(e.target.value)}
                  className="mt-1 block w-full text-lg rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
              {formError && (
                <div className="text-red-500 text-sm mt-2">{formError}</div>
              )}
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowCreateDialog(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:scale-105 transition duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={createOrUpdateSession}
                  className="bg-primary text-white px-4 py-2 rounded-md hover:scale-105 transition duration-300"
                >
                  {editingSession ? "Update Session" : "Create Session"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sessiondetails;
