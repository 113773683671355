import React from "react";
import "./Herosection.css";
// import stepping_hero from "../assets/images/stepping_hero.svg";

import Hero2 from "../assets/images/Hero_sectioncrop.jpg";
import { Link } from "react-router-dom";
import ConnectWithFounders from "./ConnectWithFounders";

function HeroSection() {
  return (
    <div
      className="flex flex-col md:flex-row px-4 pt-16 md:px-8 xl:px-20 md:h-lvh items-center justify-between md:text-lg text-tertiary-500 bg-cover md:bg-center bg-right bg-blend-lighten"
      style={{
        backgroundImage: `linear-gradient(90deg, rgba(255, 255, 255, 0.9), transparent), url(${Hero2})`,
      }}
    >
      <div className=" md:w-1/2">
        <div className="flex justify-center  text-4xl mt-12 md:text-6xl mb-6 bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 bg-clip-text text-transparent font-bold md:mb-16">
          Unlock Your Future with the Mentors Who’ve Been There
        </div>

        <div className="flex justify-center  text-primary font-medium mt-10 text-sm md:text-lg 2xl:text-xl mb-6 md:mb-10">
          At Unibridge, we connect you with mentors who have successfully
          navigated the challenges you can face in your academic and
          professional journey.
        </div>

        <div className="flex mx-auto justify-center py-4  align-middle">
          <Link to="/signup">
            <button className="bg-primary text-white border-2 border-primary m-0 py-3 px-6 font-medium rounded transition duration-300 transform hover:scale-105">
              Get Started Now
            </button>
          </Link>
          <ConnectWithFounders />
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
